import UseAxios from '@/composables/types/useAxios'
import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'
import { BasicEntity, MetaProperties } from '@/api/types/misc'
import { Hateoas } from '@/api/types/hateoas'
import { FacilityConstructId } from '@/api/types/facilityConstruct'

interface PspAccount extends MetaProperties, Hateoas {
  name: string
  description: string
  abbreviation: string
  tag: BasicEntity | null
  facilityConstruct: BasicEntity | null
}

type PspAccountId = PspAccount['id']

type NewPspAccount = Omit<PspAccount, keyof MetaProperties | keyof Hateoas | 'facilityConstruct'> & {
  facilityConstruct: FacilityConstructId
}

enum PSP_ACCOUNT_TAG {
  LINIE = 'linie',
  VENDO = 'vendo',
  VENDO_INV = 'vendoinv',
}

type PspAccountBasicEntity = BasicEntity & { tag: PSP_ACCOUNT_TAG }

type UseGetPspAccounts = UseAxiosPaginated<PspAccount>
type UseGetPspAccountsBasic = UseAxios<BasicEntity[]>
type UseCreatePspAccount = { createPspAccount: (data: NewPspAccount) => Promise<PspAccount> } & UseAxios<PspAccount>
type UseUpdatePspAccount = {
  updatePspAccount: (id: PspAccountId, data: PspAccount) => Promise<PspAccount>
} & UseAxios<PspAccount>
type UseDeletePspAccount = {
  deletePspAccount: (id: PspAccountId) => Promise<void>
} & UseAxios<void>

export {
  PspAccount,
  PspAccountId,
  NewPspAccount,
  PSP_ACCOUNT_TAG,
  PspAccountBasicEntity,
  UseGetPspAccounts,
  UseGetPspAccountsBasic,
  UseCreatePspAccount,
  UseUpdatePspAccount,
  UseDeletePspAccount,
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "min-width": "400px",
      "elevation": "1"
    }
  }, [_c('v-card-text', [_vm._l(Object.entries(_vm.budgetData), function (entry) {
    return _c('div', {
      key: entry[0]
    }, [entry[0] !== 'pspAccounts' ? _c('v-row', [_c('v-col', {
      staticClass: "py-2 px-2"
    }, [_c('div', {
      staticClass: "text-right font-weight-bold"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("persons.profile.tabMenu.budget.infobox.".concat(entry[0]))) + " ")])])]), _c('v-col', {
      staticClass: "py-2 px-2"
    }, [_c('div', {
      staticClass: "text-left"
    }, [_c('span', [_vm._v(" " + _vm._s(entry[1] ? entry[1] : '-') + " ")])])])], 1) : _vm._e()], 1);
  }), _vm.state.isRightBox && _vm.state.pspAccountObjects.length ? _c('div', _vm._l(_vm.state.pspAccountObjects, function (pspAccount) {
    return _c('v-row', {
      key: pspAccount.tag
    }, [_c('v-col', {
      staticClass: "py-2 px-2"
    }, [_c('div', {
      staticClass: "text-right font-weight-bold"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("persons.profile.tabMenu.budget.infobox.".concat(_vm.functions.normalizePspAccountTag(pspAccount.tag)))) + " ")])])]), _c('v-col', {
      staticClass: "py-2 px-2"
    }, [_c('div', {
      staticClass: "text-left"
    }, [_c('span', [_vm._v(" " + _vm._s(pspAccount.name ? pspAccount.name : '-') + " ")])])])], 1);
  }), 1) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }

import { defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'

import { BudgetDataLeft, BudgetDataRight } from '@/views/persons/views/personProfile/types'
import { PspAccountBasicEntity, PSP_ACCOUNT_TAG } from '@/api/types/pspAccount'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'BudgetInfoBox',
  props: {
    budgetData: {
      type: Object as PropType<BudgetDataLeft | BudgetDataRight>,
      required: true,
    },
  },
  setup: (props, { root }) => {
    const PSP_ACCOUNT_TAGS: PSP_ACCOUNT_TAG[] = [
      PSP_ACCOUNT_TAG.LINIE,
      PSP_ACCOUNT_TAG.VENDO,
      PSP_ACCOUNT_TAG.VENDO_INV,
    ]

    const isRightBox = isOfType<BudgetDataRight>(props.budgetData, 'pspAccounts')

    const pspAccountObjects = ref<PspAccountBasicEntity[]>(createPspAccountObjects())

    watch(props.budgetData, mapBlankPspObjsToBudgetData, { immediate: true })

    function mapBlankPspObjsToBudgetData(): void {
      if (isRightBox) {
        pspAccountObjects.value.forEach((pspAccountObject) => {
          const matchingPspAccount = (props.budgetData as BudgetDataRight).pspAccounts.find(
            (pspAccount) => normalizePspAccountTag(pspAccount.tag) === pspAccountObject.tag
          )

          if (!matchingPspAccount) return

          root.$set(
            pspAccountObjects.value,
            pspAccountObjects.value.findIndex((pspObj) => pspObj.tag === pspAccountObject.tag),
            matchingPspAccount
          )
        })
      }
    }

    function createPspAccountObjects(): PspAccountBasicEntity[] {
      return PSP_ACCOUNT_TAGS.map((tag) => ({ id: -1, name: '', tag }))
    }

    function normalizePspAccountTag(tag: string): string {
      return tag.replace(' ', '').toLowerCase()
    }

    return reactive({
      state: {
        isRightBox,
        pspAccountObjects,
      },
      functions: {
        normalizePspAccountTag,
      },
    })
  },
})
